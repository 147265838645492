<template>
	<div class="EditPopin">
		<v-card
		flat
		outlined
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>
					{{
						false === isUserEdit
							? "Ajouter un utilisateur"
							: "Modifier un utilisateur"
					}}
				</h3>
			</v-card-title>

			<v-form
			ref="form"
			class="form"
			@submit.prevent="confirmModifications()"
			>
				<v-row>
					<v-col
					cols="12"
					md="4"
					>
						<v-text-field
						autofocus
						tabindex="1"
						outlined
						label="Prénom *"
						clearable
						v-model="newUser.firstname"
						:rules="[$rules.required, $rules.notBlank, $rules.max50]"
						dense
						ref="firstname"
						@focus="$refs.firstname.resetValidation()"
						@blur="
							newUser.firstname === '' || newUser.firstname === null
								? $refs.firstname.resetValidation()
								: ''
						"
						:validate-on-blur="true"
						/>
					</v-col>

					<v-col
					cols="12"
					md="4"
					>
						<v-text-field
						label="Nom *"
						:rules="[$rules.required, $rules.notBlank, $rules.max50]"
						tabindex="2"
						clearable
						outlined
						v-model="newUser.lastname"
						dense
						ref="lastname"
						@focus="$refs.lastname.resetValidation()"
						@blur="
							newUser.lastname === '' || newUser.lastname === null
								? $refs.lastname.resetValidation()
								: ''
						"
						:validate-on-blur="true"
						/>
					</v-col>

					<v-col
					cols="12"
					md="4"
					>
						<v-text-field
						tabindex="3"
						:rules="[$rules.required, $rules.validEmail]"
						outlined
						clearable
						type="email"
						label="Email *"
						v-model="newUser.email"
						@keydown.space.prevent
						dense
						ref="email"
						@focus="$refs.email.resetValidation()"
						@blur="
							newUser.email === '' || newUser.email === null
								? $refs.email.resetValidation()
								: ''
						"
						:validate-on-blur="true"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col
					cols="12"
					md="4"
					v-if="organization.isInternal"
					>
						<v-autocomplete
						label="Métier *"
						tabindex="4"
						outlined
						clearable
						:items="jobsList"
						item-text="fr"
						:rules="[$rules.required]"
						item-value="en"
						v-model="newUser.job"
						dense
						:validate-on-blur="true"
						/>
					</v-col>

					<v-col
					cols="12"
					md="8"
					>
						<v-autocomplete
						outlined
						tabindex="5"
						label="Rôles complémentaires"
						:items="rolesList"
						item-value="en"
						item-text="fr"
						clearable
						multiple
						small-chips
						deletable-chips
						v-model="newUser.roles"
						dense
						:validate-on-blur="true"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col
					cols="12"
					md="4"
					>
						<v-text-field
						tabindex="8"
						outlined
						label="Mobile"
						:rules="[$rules.validPhoneNumber]"
						ref="mobile"
						@focus="$refs.mobile.resetValidation()"
						@blur="
							newUser.mobile === '' ? $refs.mobile.resetValidation() : ''
						"
						:validate-on-blur="true"
						clearable
						v-model="newUser.mobile"
						dense
						/>
					</v-col>

					<v-col
					cols="12"
					md="7"
					>
						<v-autocomplete
						tabindex="6"
						outlined
						label="Site(s) *"
						:items="sites"
						multiple
						:rules="[$rules.atLeastOneSite]"
						clearable
						item-value="id"
						item-text="name"
						small-chips
						v-model="newUser.sites"
						dense
						:validate-on-blur="true"
						/>
					</v-col>

					<v-col
					cols="12"
					md="1"
					class="tw-flex tw-flex-col tw-items-end"
					>
						<medium-button-slot
						v-if="$hasRight('organizations.create')"
						@click="isSiteCreateModaleOpened = true"
						>
							<v-icon>mdi-plus</v-icon>
						</medium-button-slot>
					</v-col>
					<!--<v-col cols="12" md="4">
            <v-text-field
              tabindex="7"
              outlined
              label="Téléphone"
              v-model="newUser.phone"
              :rules="[$rules.validPhoneNumber]"
              ref="phone"
              @focus="$refs.phone.resetValidation()"
              @blur="newUser.phone === '' ? $refs.phone.resetValidation() : ''"
              :validate-on-blur="true"
              clearable
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              tabindex="8"
              outlined
              label="Mobile"
              :rules="[$rules.validPhoneNumber]"
              ref="mobile"
              @focus="$refs.mobile.resetValidation()"
              @blur="
                newUser.mobile === '' ? $refs.mobile.resetValidation() : ''
              "
              :validate-on-blur="true"
              clearable
              v-model="newUser.mobile"
              dense
            ></v-text-field>
          </v-col>-->
				</v-row>

				<p v-if="isRoleAbove">
					Vous ne pouvez pas donner un rôle plus puissant que le votre, ni
					modifier un utilisateur plus haut dans la hiérarchie.
				</p>

				<v-card-actions class="pr-0 pl-0">
					<MediumButtonSlot @click="closePopin">
						Annuler
					</MediumButtonSlot>

					<v-spacer/>

					<HighButtonSlot
					_type="submit"
					:_disabled="isRoleAbove || !isValidForm()"
					>
						Valider
					</HighButtonSlot>
				</v-card-actions>
			</v-form>
		</v-card>

		<SiteCreateModale
		v-if="isSiteCreateModaleOpened"
		:organization="organization"
		@closePopin="isSiteCreateModaleOpened = false"
		@siteCreated="siteCreatedHandler"
		:siteToEdit="null"
		:isSiteEdit="false"
		/>
	</div>
</template>

<script>
import SiteCreateModale from "@/components/entities/site/CreateSite.modal.vue";

export default {
	components: {
		SiteCreateModale
	},
	props: [
		"organization", "isUserEdit", "userToEdit", "forceMainCustomer"
	],
	data(){
		return {
			isSiteCreateModaleOpened: false,
			newUser: {
				mobile: null,
				phone: null,
				firstname: "",
				lastname: "",
				email: "",
				job: "",
				isInternal: this.organization.isInternal,
				roles: [],
				sites: []
			},
			internalRoles: [
				{en: "ROLE_NEXTROAD_ADMIN", fr: this.$t("ROLE_NEXTROAD_ADMIN")},
				{en: "ROLE_NEXTROAD_MAIN", fr: this.$t("ROLE_NEXTROAD_MAIN")}
			],
			internalAdminRoles: [
				{en: "ROLE_ADMIN", fr: this.$t("ROLE_ADMIN")},
				{en: "ROLE_SUPER_ADMIN", fr: this.$t("ROLE_SUPER_ADMIN")},
				{en: "ROLE_NEXTROAD_ADMIN", fr: this.$t("ROLE_NEXTROAD_ADMIN")},
				{en: "ROLE_NEXTROAD_MAIN", fr: this.$t("ROLE_NEXTROAD_MAIN")}
			],
			externalRoles: [
				{en: "ROLE_CUSTOMER_MAIN", fr: this.$t("ROLE_CUSTOMER_MAIN")},
				{
					en: "ROLE_CUSTOMER_SUPERVISOR",
					fr: this.$t("ROLE_CUSTOMER_SUPERVISOR")
				}
			],
			jobsList: [
				{en: "technician", fr: this.$t("technician")},
				{en: "study_manager", fr: this.$t("study_manager")},
				{en: "commercial", fr: this.$t("commercial")},
				{en: "engineer", fr: this.$t("engineer")}
			],
			sites: []
		};
	},
	computed: {
		rolesList(){
			if(this.organization.isInternal){
				if(this.organization.id === "1a89068c-96b7-43dd-882c-69e3b9c68e45"){
					// Si organisation admin, alors on retour les rôles admin / super admin
					return this.internalAdminRoles;
				}
				else {
					return this.internalRoles;
				}
			}
			else return this.externalRoles;
		},
		isRoleAbove(){
			let hasRoleAbove = false;
			this.newUser.roles.forEach(role => {
				hasRoleAbove = this.$isRoleAbove(role) ? true : hasRoleAbove;
			});
			return hasRoleAbove;
		}
	},
	methods: {
		isValidForm(){
			return (
				this.newUser.firstname.length > 0 &&
        this.newUser.firstname.length <= 50 &&
        this.newUser.lastname.length > 0 &&
        this.newUser.lastname.length <= 50 &&
        this.newUser.email.length > 2 &&
        this.newUser.sites.length > 0 /*&&
        this.$rules.validPhoneNumber(this.newUser.mobile) === true &&
        this.$rules.validPhoneNumber(this.newUser.phone) === true*/
			);
		},
		getUserType(){
			const userRoles = this.newUser.roles;

			if(userRoles.includes("ROLE_SUPER_ADMIN")) return "super_admin";
			if(userRoles.includes("ROLE_ADMIN")) return "admin";
			if(userRoles.includes("ROLE_NEXTROAD_ADMIN")) return "nextroad_admin";
			if(userRoles.includes("ROLE_NEXTROAD_MAIN")) return "nextroad_main";
			if(userRoles.includes("ROLE_NEXTROAD_USER")) return "nextroad";
			if(userRoles.includes("ROLE_CUSTOMER_SUPERVISOR")) return "customer_supervisor";
			if(userRoles.includes("ROLE_CUSTOMER_MAIN")) return "customer_main";
			if(userRoles.includes("ROLE_CUSTOMER_USER")) return "customer";
			else return "";
		},
		async getSites(){
			this.sites = await this.$api.organizations.getAllSitesFromOrganization(
				this.organization.id
			);
		},
		closePopin(){
			this.$emit("closePopin");
		},
		confirmModifications(){
			if(!this.isValidForm()) return console.error("invalid form");
			if(this.newUser.job === "") this.newUser.job = null;
			if(this.forceMainCustomer === true){
				if(!this.newUser.roles.includes("ROLE_CUSTOMER_MAIN")){
					this.newUser.roles.push("ROLE_CUSTOMER_MAIN");
				}
			}

			if(false === this.isUserEdit){
				this.$api.users
				.create(
					{
						...this.newUser,
						siteIds: this.newUser.sites,
						phone: this.newUser.phone,
						mobile: this.newUser.mobile,
						plainPassword: this.organization.id,
						userType: this.getUserType()
					},
					// email not activated (https://github.com/Fogo-Capital/maorie-monolith/issues/947)
					false
				)
				.catch(error => {
					console.log(error);
				})
				.then(newUser => {
					this.$emit("userCreated", newUser);
					this.closePopin();
				});
			}
			else if(true === this.isUserEdit){
				let sitesToPersists = this.newUser.sites.map((e) => {
					return e.id;
				});

				this.$api.users
				.edit(this.newUser.id, {
					...this.newUser,
					siteIds: sitesToPersists,
					mobile: this.newUser.mobile,
					phone: this.newUser.phone
				})
				.then(() => {
					this.$emit("userEdited");
					this.closePopin();
				});
			}
		},
		async siteCreatedHandler(site){
			this.isSiteCreateModaleOpened = false;
			await this.getSites();
			this.newUser.sites.push(site);
		},
		sortFields(){
			this.jobsList.sort(function(a, b){
				return a.fr.localeCompare(b.fr);
			});
			this.sites.sort(function(a, b){
				return a.name.localeCompare(b.name);
			});
		}
	},
	async mounted(){
		this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
		await this.getSites();
		this.sortFields();

		if(this.forceMainCustomer === true){
			this.newUser.roles.push("ROLE_CUSTOMER_MAIN");
		}

		if(undefined !== this.userToEdit){
			this.newUser = {
				...this.userToEdit,
				mobile: this.userToEdit.contact.mobile,
				phone: this.userToEdit.contact.phone
			};
		}
		else if(false === this.organization.isInternal && undefined !== this.userToEdit){
			this.newUser.roles.push("ROLE_CUSTOMER_MAIN");
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.EditPopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}

.row {
  margin-top: 0 !important;
}
</style>
