var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"TableCard",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-1 pr-1"},[_c('h3',[_vm._v(_vm._s(_vm.organization.isInternal || _vm.$hasRight('users.isCustomerSupervisor') ? 'Utilisateurs': 'Clients'))])]),_c('v-card-text',{staticClass:"pl-1 pr-2"},[_c('div',{staticClass:"search-row"},[_c('div',{staticClass:"search-field"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.organization.isInternal || _vm.$hasRight('users.isCustomerSupervisor') ? 'Rechercher des utilisateurs': 'Rechercher des clients',"single-line":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}})],1),_c('div',{staticClass:"add-field"},[(_vm.$hasRight('users.create'))?_c('medium-button-slot',{staticClass:"sendInviteButton",on:{"click":function($event){return _vm.openUserCreationModal()}}},[_vm._v(" "+_vm._s(_vm.$t("add_user"))+" ")]):_vm._e()],1),(_vm.selectedUsers.length && _vm.$hasRight('user.sendInviteLinks'))?_c('medium-button-slot',{staticClass:"sendInviteButton",on:{"click":function($event){return _vm.sendRecoveryLinks()}}},[_vm._v(" envoyer "+_vm._s(_vm.selectedUsers.length > 1 ? ("les " + (_vm.selectedUsers.length) + " invitations") : "l'invitation")+" ")]):_vm._e()],1),_c('v-data-table',{staticClass:"bordered",attrs:{"loading":_vm.users === [],"headers":_vm.userHeaders,"items":_vm.users,"items-per-page":10,"search":_vm.userSearch,"show-select":"","hide-default-footer":_vm.users.length < 11},scopedSlots:_vm._u([{key:"item.sites",fn:function(ref){
var item = ref.item;
return _vm._l((item.sites),function(site,key){return _c('span',{key:key},[(site.organization.id === _vm.organization.id)?_c('v-chip',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(site.name)+" ")]):_vm._e()],1)})}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions"},[(_vm.$hasRight('users.copyInviteLink'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",on:{"click":function($event){return _vm.copy(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-link ")])]}}],null,true)},[_c('span',[_vm._v("Copier un lien d'invitation")])]):_vm._e(),(_vm.$hasRight('users.sendInviteLinks'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",attrs:{"color":"#2a7221"},on:{"click":function($event){return _vm.$api.users.sendInvite(item.id, item.email, true)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-send ")])]}}],null,true)},[_c('span',[_vm._v("Envoyer un lien d'invitation")])]):_vm._e(),(_vm.$hasRight('users.edit'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",attrs:{"color":"#e89005"},on:{"click":function($event){return _vm.openUserEditionModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pen ")])]}}],null,true)},[_c('span',[_vm._v("Modifier l'utilisateur")])]):_vm._e(),(_vm.$hasRight('users.delete'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",attrs:{"color":"#b80000"},on:{"click":function($event){return _vm.openUserDeletionModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-minus ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer l'utilisateur")])]):_vm._e()],1)]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRolesTranslation(item.roles))+" ")]}},{key:"item.job",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.job))+" ")]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),(_vm.isUserDeleteModaleOpen)?_c('UserDeleteModale',{attrs:{"user":_vm.currentUser},on:{"closePopin":function($event){_vm.isUserDeleteModaleOpen = false},"userDeleted":function($event){return _vm.$emit('refreshData')}}}):_vm._e(),(_vm.isUserCreateModaleOpen)?_c('CreateEditUserModal',{attrs:{"organization":_vm.organization,"isUserEdit":false},on:{"closePopin":function($event){_vm.isUserCreateModaleOpen = false},"userCreated":function($event){return _vm.$emit('refreshData')}}}):_vm._e(),(_vm.isUserEditModaleOpen)?_c('CreateEditUserModal',{attrs:{"organization":_vm.organization,"isUserEdit":true,"userToEdit":_vm.currentUser},on:{"closePopin":function($event){_vm.isUserEditModaleOpen = false},"userEdited":function($event){return _vm.$emit('refreshData')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }