<template>
	<div class="DeletePopin">
		<v-card
		flat
		outlined
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>
					Confirmer la suppression de l'organisation {{ organization.name }} ?
				</h3>
			</v-card-title>

			<v-card-text class="card_text">
				<div v-if="hasLinkedEntities">
					<p>
						Cette organisation est liée à des projets existants, vous ne pouvez
						pas la supprimer. Pour obtenir une aide complémentaire, merci de
						contacter le support à cette adresse : support@maorie.com
					</p>

					<div class="entitiesLists">
						<div class="entityList">
							<b>Projets liés</b>

							<ul>
								<li
								v-for="p in entities.workspaces"
								:key="p.id"
								>
									{{ p.name }}
								</li>
							</ul>
						</div>

						<div class="entityList">
							<b>Sites liés</b>

							<ul>
								<li
								v-for="a in entities.sites"
								:key="a.id"
								>
									{{ a.name }}
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div v-else>
					<p v-if="!hasLinkedEntities">
						Êtes-vous sûr de vouloir supprimer l'organisation
						{{ organization.name }} ?
					</p>

					<p>Cette action est irréversible</p>
				</div>
			</v-card-text>

			<v-card-actions>
				<LowButtonSlot @click="closePopin">
					Annuler
				</LowButtonSlot>

				<v-spacer/>

				<HighButtonSlot
				:_disabled="hasLinkedEntities"
				@click="confirmOrganizationDeletion"
				>
					Valider
				</HighButtonSlot>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
export default {
	props: ["organization"],
	data(){
		return {
			entities: {
				projects: [],
				sites: [],
			},
		};
	},
	computed: {
		hasLinkedEntities(){
			return (
				0 < this.entities.sites.length || 0 < this.entities.projects.length
			);
		},
	},
	methods: {
		closePopin(){
			this.$emit("closePopin");
		},
		async confirmOrganizationDeletion(){
			await this.$api.organizations.delete(this.organization.id);
			this.$router.push({name: "organizations-administration"});
			this.closePopin();
		},
		async getOrganizationEntities(){
			this.entities = await this.$api.organizations.getRelations(
				this.organization.id
			);
		},
	},
	mounted(){
		this.getOrganizationEntities();
		this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.DeletePopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }

  .entitiesLists {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;

    .entityList {
      margin: 10px;

      ul {
        margin-top: 10px;
      }
    }
  }
}
</style>
