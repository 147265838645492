<template>
	<v-card
	class="TableCard"
	flat
	>
		<v-card-title class="pl-1 pr-1">
			<h3>Sites</h3>
		</v-card-title>

		<v-card-text class="pl-1 pr-2">
			<div class="search-row">
				<div class="search-field">
					<v-text-field
					v-model="siteSearch"
					append-icon="mdi-magnify"
					:label="$t('search_site')"
					single-line
					hide-details
					clearable
					dense
					/>
				</div>

				<div class="add-field">
					<MediumButtonSlot
					v-if="($hasRight('sites.create') && organization.id !== '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3') || (organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && $hasRight('globalActions.superAdmin'))"
					@click="openSiteCreationModal()"
					>
						{{ $t("add_site") }}
					</MediumButtonSlot>
				</div>
			</div>

			<v-data-table
			:loading="sites === []"
			:headers="organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' ? sitesHeadersBE : sitesHeaders"
			:items="sites"
			:items-per-page="5"
			:search="siteSearch"
			:hide-default-footer="sites.length < 6"
			class="bordered"
			>
				<template
				v-slot:item.agency="{ item }"
				v-if="organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3'"
				>
					<v-chip
					v-if="item.agency !== null"
					small
					class="mr-2"
					>
						{{ item.agency.name }}
					</v-chip>
				</template>
				<template v-slot:item.edit="{ item }">
					<div class="table-actions">
						<v-tooltip
						top
						v-if="($hasRight('sites.edit') && organization.id !== '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3') || (organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && $hasRight('globalActions.superAdmin'))"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								@click="openSiteEditionModal(item)"
								class="table-action"
								color="#e89005"
								v-bind="attrs"
								v-on="on"
								>
									mdi-pen
								</v-icon>
							</template>

							<span>Modifier le site</span>
						</v-tooltip>

						<v-tooltip
						top
						v-if="($hasRight('sites.deactivate') && organization.id !== '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3') || (organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && $hasRight('globalActions.superAdmin'))"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								@click="openSiteDeactivationModal(item)"
								class="table-action"
								color="#b80000"
								v-bind="attrs"
								v-on="on"
								>
									mdi-lock-clock
								</v-icon>
							</template>

							<span>Suspendre l'accès aux campagnes</span>
						</v-tooltip>

						<v-tooltip
						top
						v-if="($hasRight('sites.delete') && organization.id !== '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3') || (organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && $hasRight('globalActions.superAdmin'))"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								@click="openSiteDeletionModal(item)"
								class="table-action"
								color="#b80000"
								v-bind="attrs"
								v-on="on"
								>
									mdi-delete-forever
								</v-icon>
							</template>

							<span>Supprimer le site</span>
						</v-tooltip>
					</div>
				</template>
			</v-data-table>
		</v-card-text>

		<SiteDeactivationModal
		v-if="isSiteDeactivatedModaleOpen"
		@closeCurrent="isSiteDeactivatedModaleOpen = false"
		type="site"
		:siteOrOrganization="currentSite"
		@refreshSite="$emit('refreshData')"
		/>

		<SiteDeleteModale
		v-if="isSiteDeleteModaleOpen"
		:site="currentSite"
		:sites="sites"
		:campaigns="campaigns"
		@closePopin="isSiteDeleteModaleOpen = false"
		@siteDeleted="$emit('refreshData')"
		/>

		<SiteCreateModale
		v-if="isSiteEditModaleOpen"
		:organization="organization"
		:site-to-edit="currentSite"
		:is-site-edit="true"
		@closePopin="isSiteEditModaleOpen = false"
		@siteEdited="$emit('refreshData')"
		/>

		<SiteCreateModale
		v-if="isSiteCreateModaleOpen"
		:organization="organization"
		:site-to-edit="null"
		:is-site-edit="false"
		@closePopin="isSiteCreateModaleOpen = false"
		@siteCreated="$emit('refreshData')"
		/>
	</v-card>
</template>

<script>
import SiteCreateModale from "../../entities/site/CreateSite.modal.vue";
import SiteDeleteModale from "../../entities/site/DeleteSite.modal.vue";
import HighButtonSlot from "../../slots/buttons/High.button.slot.vue";
import MediumButtonSlot from "../../slots/buttons/Medium.button.slot.vue";
import SiteDeactivationModal from "./SiteDeactivationModal.vue";

export default {
	components: {
		SiteDeleteModale,
		SiteCreateModale,
		MediumButtonSlot,
		HighButtonSlot,
		SiteDeactivationModal
	},
	props: [
		"sites", "organization", "campaigns"
	],
	data(){
		return {
			sitesHeadersBE: [
				{text: "Nom", value: "name"},
				{text: "Email", value: "email"},
				{text: "Téléphone", value: "contact.phone"},
				{text: "Agence", value: "agency", sortable: false},
				{
					text: "Modifier", value: "edit", align: "center", sortable: false
				}
			],
			sitesHeaders: [
				{text: "Nom", value: "name"},
				{text: "Email", value: "email"},
				{text: "Téléphone", value: "contact.phone"},
				{
					text: "Modifier", value: "edit", align: "center", sortable: false
				}
			],

			siteSearch: "",
			currentSite: {},

			isSiteDeleteModaleOpen: false,
			isSiteEditModaleOpen: false,
			isSiteCreateModaleOpen: false,
			isSiteDeactivatedModaleOpen: false,
		};
	},
	methods: {
		openSiteEditionModal(site){
			this.currentSite = site;
			this.isSiteEditModaleOpen = true;
		},
		openSiteDeletionModal(site){

			if(site.deactivation !== null){
				this.expirationDate = site.deactivation;
			}
			this.currentSite = site;
			this.isSiteDeleteModaleOpen = true;
		},
		openSiteCreationModal(){
			this.isSiteCreateModaleOpen = true;
		},
		openSiteDeactivationModal(site){
			this.currentSite = site;
			this.isSiteDeactivatedModaleOpen = true;
		},
		closeSiteDeactivationModale(){
			this.isSiteDeactivatedModaleOpen = false;
		},
	}
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";

.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
