<template>
	<div class="organization tw-p-[24px]">
		<HeaderSlot
		:title="'Administration de ' + organization.name"
		subTitle="Gestion de l'organisation"
		@clickBackButton="$router.back()"
		>
			<ButtonSlot
			@click="deactivateSiteFromOrganisation()"
			v-if="($hasRight('sites.editAccess') && organization.id !== '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3')|| (organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && $hasRight('globalActions.superAdmin'))"
			>
				Gérer l'accès
			</ButtonSlot>

			<HighButtonSlot
			v-if="($hasRight('organizations.changeInfos') && organization.id !== '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3')|| (organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && $hasRight('globalActions.superAdmin'))"
			@click="openDeleteOrganizationModale()"
			>
				Supprimer l'organisation
			</HighButtonSlot>
		</HeaderSlot>

		<div class="scrollable_container pa-1 pr-4">
			<v-card v-if="$hasRight('organizations.changeInfos')">
				<v-card-title>
					<h3>Informations de l'organisation</h3>
				</v-card-title>

				<v-card-text>
					<v-row>
						<v-col cols="5">
							<v-text-field
							v-if="isOrganizationEditMode"
							outlined
							clearable
							label="Nom *"
							dense
							v-model="newOrganizationName"
							hide-details
							class="tw-mb-[10px]"
							/>

							<p v-else>
								Nom : {{ organization.name }}
							</p>

							<v-select
							dense
							outlined
							clearable
							v-if="
								$hasRight('globalActions.superAdmin') &&
									organization.isInternal === false && isOrganizationEditMode && !newOrganizationIsInternal
							"
							label="Organisation parent"
							:items="parentOrganizations"
							v-model="newOrganizationParentId"
							hide-details
							item-text="name"
							item-value="id"
							/>

							<v-checkbox
							color="#2a7221"
							v-if="
								$hasRight('organizations.manageAll') && isOrganizationEditMode
							"
							label="Organisation bureau d'étude"
							v-model="newOrganizationIsInternal"
							/>

							<p
							class="mt-4"
							v-else
							>
								Bureau d'étude : {{ organization.isInternal ? "oui" : "non" }}
							</p>
						</v-col>

						<v-col cols="2"/>

						<v-col
						cols="5"
						align="center"
						>
							<v-col>
								<v-row justify="center">
									<div class="center-p">
										<p class="mr-2 mb-0">
											Logo de l'organisation qui<br/>
											apparaîtra sur vos livrables :
										</p>
									</div>

									<v-img
									class="logo_image mb-5 tw-relative"
									:src="deleteImg !== false ? '' : newOrganizationLogo"
									contain
									>
										<v-icon
										v-if="isOrganizationEditMode && newOrganizationLogo !== null && deleteImg !== newOrganizationLogo && organization.logoPath"
										class="tw-absolute tw-bottom-[5px] tw-right-[5px] tw-text-[rgb(184,0,0)]"
										@click="deleteImg = newOrganizationLogo"
										>
											mdi-delete-forever
										</v-icon>
									</v-img>
								</v-row>
							</v-col>

							<div>
								<v-file-input
								v-if="isOrganizationEditMode"
								class="mt-0 pt-0"
								type="file"
								accept=".png, .jpg, .jpeg"
								v-model="uploadedImg"
								label="Ajouter un logo"
								@change="inputFile"
								/>
							</div>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<MediumButtonSlot
					v-if="isOrganizationEditMode"
					@click="cancel"
					>
						Annuler
					</MediumButtonSlot>

					<v-spacer/>

					<HighButtonSlot
					v-if="isOrganizationEditMode"
					@click="saveOrganization"
					>
						Valider
					</HighButtonSlot>

					<v-icon
					v-if="!isOrganizationEditMode"
					color="#e89005"
					@click="isOrganizationEditMode = !isOrganizationEditMode"
					>
						mdi-pen
					</v-icon>
				</v-card-actions>
			</v-card>

			<SiteTable
			@refreshData="refreshViewData()"
			:sites="sites"
			:organization="organization"
			:campaigns="campaigns"
			/>

			<UserTable
			@refreshData="refreshViewData()"
			:users="organizationUsers"
			:organization="organization"
			/>

			<ProjectTable :projects="projects"/>

			<CampaignTable :campaigns="campaigns"/>
		</div>

		<OrganizationDeleteModale
		v-if="isDeleteOrgaModaleDisplayed"
		:organization="organization"
		@closePopin="closeOrganizationDeleteModale()"
		/>

		<SiteDeactivationModal
		v-if="isOpenDeactivationModal"
		@closeCurrent="isOpenDeactivationModal = false"
		type="organization"
		:siteOrOrganization="siteOrOrganization"
		/>
	</div>
</template>

<script>
import UserTable from "@/components/administration/dashboard/User.table.vue";
import SiteTable from "@/components/administration/dashboard/Site.table.vue";
import CampaignTable from "@/components/administration/dashboard/Campaign.table.vue";
import OrganizationDeleteModale from "@/components/entities/organization/DeleteOrganization.modal";
import SiteDeactivationModal from "../../components/administration/dashboard/SiteDeactivationModal.vue";
import ProjectTable from "../../components/administration/dashboard/Project.table.vue";

export default {
	components: {
		SiteTable,
		UserTable,
		CampaignTable,
		OrganizationDeleteModale,
		SiteDeactivationModal,
		ProjectTable
	},
	data(){
		return {
			isDeleteOrgaModaleDisplayed: false,
			isOrganizationEditMode: false,

			organization: {
				name: ""
			},
			newOrganizationName: "",
			newOrganizationIsInternal: false,
			newOrganizationLogo: null,
			newOrganizationParentId: null,

			currentLogoPath: null,
			isItAnUpload: false,
			uploadedImg: null,
			deleteImg: false,
			logoSource: null,
			organizationUsers: [],
			sites: [],
			campaigns: [],
			projects: [],
			isOpenDeactivationModal: false,
			siteOrOrganization: false,
			parentOrganizations: []
		};
	},
	watch: {
		newOrganizationIsInternal(){
			if(this.newOrganizationIsInternal === true){
				this.parentOrganizationId = null;
			}
			else {

				this.newOrganizationParentId = this.organization.parentOrganization;
			}
		}
	},
	methods: {
		getParentOrganization(){
			this.$api.organizations.findAll().then(organizations => {
				this.parentOrganizations = organizations.filter(
					organization => organization.parentOrganization === null
				);
			});
		},
		inputFile(e){
			if(e === null){
				this.newOrganizationLogo = this.logoSource;
			}
			else {
				this.newOrganizationLogo = URL.createObjectURL(e);
			}
		},
		openDeleteOrganizationModale(){
			this.isDeleteOrgaModaleDisplayed = true;
		},
		closeOrganizationDeleteModale(){
			this.isDeleteOrgaModaleDisplayed = false;
		},
		saveOrganization(){
			if(this.uploadedImg !== null){
				var formData = new FormData();
				formData.append("file", this.uploadedImg);
				this.$api.organizations.uploadFile(formData, this.organization.id).then(data => {
					this.$api.organizations
					.edit(this.organization.id, {
						name: this.newOrganizationName,
						isInternal: this.newOrganizationIsInternal,
						logoPath: data
					})
					.then(() => {
						this.currentLogoPath = data;
						this.isItAnUpload = true;
						this.refreshViewData();
						this.uploadedImg = null;
					});
				});
			}
			else if(this.deleteImg !== false){ 
				this.$api.organizations.deleteFile(this.organization.id);
				this.deleteImg = false;
				this.newOrganizationLogo = null;
				this.organization.logoPath = null;
				this.currentLogoPath = null;
			}

			this.$api.organizations
			.edit(this.organization.id, {
				name: this.newOrganizationName,
				isInternal: this.newOrganizationIsInternal,
				logoPath: this.currentLogoPath,
				parentOrganizationId: this.newOrganizationParentId
			})
			.then(() => {
				this.refreshViewData();
			});

			this.isOrganizationEditMode = false;
		},
		async getSites(){
			this.sites = await this.$api.organizations.getAllSitesFromOrganization(
				this.$route.params.id
			);
		},
		async getOrganization(){
			this.organization = await this.$api.organizations.findById(
				this.$route.params.id
			);
			this.newOrganizationName = this.organization.name;
			this.newOrganizationIsInternal = this.organization.isInternal;
			this.currentLogoPath = this.organization.logoPath;
			this.newOrganizationParentId = this.organization.parentOrganization?.id;

			if(this.isItAnUpload === false){
				this.getOrganizationLogo();
			}
		},
		getOrganizationLogo(){
			if(this.organization.logoPath !== null){
				this.$api.organizations.getFileUrl(this.$route.params.id).then(data => {
					this.newOrganizationLogo = data;
					this.logoSource = data;
				});
			}
		},
		async getOrganizationUsers(){
			const users = await this.$api.users.findByOrganization(
				this.$route.params.id
			);

			this.organizationUsers = users.map(user => {
				const userRoleIndex = user.roles.findIndex(
					role => role === "ROLE_USER"
				);

				user.roles.splice(userRoleIndex, 1);

				return user;
			});
		},
		async getCampaigns(){
			const rawCampaigns = await this.$api.campaigns.findByUser(
				this.$store.state.user.current.userId,
				true
			);

			this.campaigns = this.organization.isInternal
				? rawCampaigns.filter(
					campaign =>
						campaign.workspace.site.organization.id === this.organization.id
				)
				: rawCampaigns.filter(
					campaign =>
						campaign.workspace.customerSite.organization.id ===
              this.organization.id
				);

			this.campaigns.sort((a, b) => a.name.localeCompare(b.name));
		},
		async getProjects(){
			this.projects = await this.$api.workspaces.findByOrganizations(this.$route.params.id);
		},
		refreshViewData(){
			if(undefined === this.$store.state.user.current?.userId) return setTimeout(this.refreshViewData, 200);
			if(!this.$hasRight("organizations.manageOne")) this.$router.push("/workspaces");
			this.getOrganization();
			this.getSites();
			this.getCampaigns();
			this.getProjects();
			this.getOrganizationUsers();
		},
		async deactivateSiteFromOrganisation(){
			this.sites.length > 1 ? 
				this.siteOrOrganization = this.organization
				: this.siteOrOrganization = this.sites[0];
			this.isOpenDeactivationModal = true;
			//this.sites = await this.$api.organizations.deactivateAllSites(this.organization.id);
		},
		cancel(){
			this.isOrganizationEditMode = false;
			if(this.organization.logoPath === null){
				this.newOrganizationLogo = null;
			}
			this.uploadedImg = null;
			this.deleteImg = false;
		}
	},
	async mounted(){
		await this.refreshViewData();
		await this.getParentOrganization();
	}
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.organization {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .d-flex,
  .d-flex .left {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.actionBtn {
  cursor: pointer;
  margin: 5px;
}
.TableCard {
  margin: 25px 0;

  .v-data-footer .v-btn > .v-btn__content .v-icon {
    color: rgb(150, 150, 150) !important;
  }

  .search-field {
    margin-right: 10px;
    width: 24%;
  }
  .search-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .add-field {
    display: flex;
    flex-flow: row-reverse;
    flex-grow: 1;
  }

  .table-actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .table-action {
      cursor: pointer;
      margin: 5px;
    }
  }
}

.logo_image {
  max-width: 136px;
  height: 136px;
  border: 1px rgba(0, 0, 0, 0.4) solid;
  border-radius: 4px;
  transition: border 0.2s ease;
}

.logo_filename {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.center-p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 136px;
}
</style>
