<template>
	<v-card
	class="TableCard"
	flat
	>
		<v-card-title class="pl-1 pr-1">
			<h3>{{ organization.isInternal || $hasRight('users.isCustomerSupervisor') ? 'Utilisateurs': 'Clients' }}</h3>
		</v-card-title>

		<v-card-text class="pl-1 pr-2">
			<div class="search-row">
				<div class="search-field">
					<v-text-field
					v-model="userSearch"
					append-icon="mdi-magnify"
					:label="organization.isInternal || $hasRight('users.isCustomerSupervisor') ? 'Rechercher des utilisateurs': 'Rechercher des clients'"
					single-line
					hide-details
					dense
					clearable
					/>
				</div>

				<div class="add-field">
					<medium-button-slot
					v-if="$hasRight('users.create')"
					class="sendInviteButton"
					@click="openUserCreationModal()"
					>
						{{ $t("add_user") }}
					</medium-button-slot>
				</div>

				<medium-button-slot
				class="sendInviteButton"
				v-if="selectedUsers.length && $hasRight('user.sendInviteLinks')"
				@click="sendRecoveryLinks()"
				>
					envoyer
					{{
						selectedUsers.length > 1
							? `les ${selectedUsers.length} invitations`
							: "l'invitation"
					}}
				</medium-button-slot>
			</div>

			<v-data-table
			:loading="users === []"
			:headers="userHeaders"
			:items="users"
			:items-per-page="10"
			:search="userSearch"
			class="bordered"
			show-select
			v-model="selectedUsers"
			:hide-default-footer="users.length < 11"
			>
				<template v-slot:item.sites="{ item }">
					<span
					v-for="(site, key) in item.sites"
					:key="key"
					>
						<v-chip
						v-if="site.organization.id === organization.id"
						small
						class="mr-2"
						>
							{{ site.name }}
						</v-chip>
					</span>
				</template>

				<template v-slot:item.edit="{ item }">
					<div class="table-actions">
						<v-tooltip
						top
						v-if="$hasRight('users.copyInviteLink')"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								class="table-action"
								@click="copy(item.id)"
								v-bind="attrs"
								v-on="on"
								>
									mdi-link
								</v-icon>
							</template>

							<span>Copier un lien d'invitation</span>
						</v-tooltip>
		
						<v-tooltip
						top
						v-if="$hasRight('users.sendInviteLinks')"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								@click="$api.users.sendInvite(item.id, item.email, true)"
								class="table-action"
								color="#2a7221"
								v-bind="attrs"
								v-on="on"
								>
									mdi-send
								</v-icon>
							</template>

							<span>Envoyer un lien d'invitation</span>
						</v-tooltip>

						<v-tooltip
						top
						v-if="$hasRight('users.edit')"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								@click="openUserEditionModal(item)"
								class="table-action"
								color="#e89005"
								v-bind="attrs"
								v-on="on"
								>
									mdi-pen
								</v-icon>
							</template>

							<span>Modifier l'utilisateur</span>
						</v-tooltip>

						<v-tooltip
						top
						v-if="$hasRight('users.delete')"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								@click="openUserDeletionModal(item)"
								class="table-action"
								color="#b80000"
								v-bind="attrs"
								v-on="on"
								>
									mdi-account-minus
								</v-icon>
							</template>

							<span>Supprimer l'utilisateur</span>
						</v-tooltip>
					</div>
				</template>

				<template v-slot:item.roles="{ item }">
					{{ getRolesTranslation(item.roles) }}
				</template>

				<template v-slot:item.job="{ item }">
					{{ $t(item.job) }}
				</template>
			</v-data-table>
		</v-card-text>

		<UserDeleteModale
		v-if="isUserDeleteModaleOpen"
		:user="currentUser"
		@closePopin="isUserDeleteModaleOpen = false"
		@userDeleted="$emit('refreshData')"
		/>

		<CreateEditUserModal
		v-if="isUserCreateModaleOpen"
		:organization="organization"
		@closePopin="isUserCreateModaleOpen = false"
		@userCreated="$emit('refreshData')"
		:isUserEdit="false"
		/>

		<CreateEditUserModal
		v-if="isUserEditModaleOpen"
		:organization="organization"
		@closePopin="isUserEditModaleOpen = false"
		@userEdited="$emit('refreshData')"
		:isUserEdit="true"
		:userToEdit="currentUser"
		/>
	</v-card>
</template>

<script>
import UserDeleteModale from "@/components/entities/user/DeleteUser.modal";
import CreateEditUserModal from "@/components/entities/user/CreateEditUser.modal";
export default {
	components: {
		UserDeleteModale,
		CreateEditUserModal
	},
	props: ["users", "organization"],
	data(){
		return {
			isUserDeleteModaleOpen: false,
			isUserEditModaleOpen: false,
			isUserCreateModaleOpen: false,

			currentUser: {},
			userSearch: "",
			selectedUsers: [],
			userHeaders: [
				{text: "Prénom", value: "firstname"},
				{text: "Nom", value: "lastname"},
				{text: "Email", value: "email"},
				{text: "Sites", value: "sites", sortable: false},
				{text: "Rôles", value: "roles"},
				{text: "Mobile", value: "contact.mobile"},
				{
					text: "Modifier", value: "edit", align: "center", sortable: false
				}
			]
		};
	},
	methods: {
		openUserEditionModal(user){
			this.currentUser = user;
			this.isUserEditModaleOpen = true;
		},
		openUserDeletionModal(user){
			this.currentUser = user;
			this.isUserDeleteModaleOpen = true;
		},
		openUserCreationModal(){
			this.isUserCreateModaleOpen = true;
		},
		async checkJobHeader(){
			const hasHeader =
        this.userHeaders.findIndex(header => header.text === "Métier") !== -1;
			if(this.organization.name === "" && !hasHeader) return setTimeout(this.checkJobHeader, 200);
			if(this.organization.isInternal && !hasHeader){
				this.userHeaders.splice(3, 0, {text: "Métier", value: "job"});
			}
		},
		getRolesTranslation(roles){
			let finalString = "";
			roles.forEach((role, index) => {
				finalString += this.$t(role);
				if(index !== roles.length - 1) finalString += ", ";
			});

			return finalString;
		},
		sendRecoveryLinks(){
			this.selectedUsers.forEach(user => {
				this.$api.sendInvite(user.id, user.email);
			});
			this.$toast("success", "Invitations envoyées");
			this.selectedUsers = [];
		},
		copy(id){
			this.$api.users.getInvite(id).then(rep => {
				window.navigator.clipboard.writeText(rep.link).then(() => {
					this.$toast("success", "Lien copié");
				});
			});
		}
	},
	mounted(){
		this.checkJobHeader();
	}
};
</script>

<style lang="scss" scoped>
.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
