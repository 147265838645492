<template>
	<v-card
	class="TableCard"
	flat
	v-if="$hasRight('campaigns.manageAll')"
	>
		<v-card-title class="pl-1 pr-1">
			<h3>Projets</h3>
		</v-card-title>

		<v-card-text class="pl-1 pr-2">
			<div class="search-row">
				<div class="search-field">
					<v-text-field
					v-model="projectSearch"
					append-icon="mdi-magnify"
					:label="$t('search_project')"
					single-line
					hide-details
					clearable
					dense
					/>
				</div>
			</div>

			<v-data-table
			:loading="projects === []"
			:headers="projectsHeaders"
			:items="projects"
			:items-per-page="5"
			:hide-default-footer="projects.length < 6"
			:search="projectSearch"
			class="bordered"
			>
				<template v-slot:item="{item}">
					<tr
					class="pointer"
					@click="handleClick(item)"
					>
						<td>{{ item.name }}</td>

						<td>{{ item.site.name }}</td>

						<td>{{ item.customerSite.name }}</td>
					</tr>
				</template>

				<template v-slot:item.edit="{ item }">
					<div class="table-actions">
						<v-tooltip
						top
						v-if="$hasRight('campaigns.accessAdminEdit')"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
								@click="openProjectEditionView(item)"
								class="table-action"
								color="#e89005"
								v-bind="attrs"
								v-on="on"
								>
									mdi-pen
								</v-icon>
							</template>

							<span>Modifier les droits des utilisateurs</span>
						</v-tooltip>
					</div>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	components: {},
	props: ["projects"],
	data(){
		return {
			projectSearch: "",

			projectsHeaders: [
				{text: "Nom", value: "name"},
				// { text: "Description", value: "description" },
				//{text: "Projet", value: "workspace.name"},
				{text: "Site interne", value: "workspace.site.name"},
				{text: "Site client", value: "workspace.customerSite.name"}
				// { text: "Modifier les droits", value: "edit", align: "center" }
			]
		};
	},
	methods: {
		handleClick(item){
			
			this.$router.push({
				name: "project",
				params: {
					id: item.id
				}
			});
		}
	},
};
</script>

<style lang="scss" scoped>
.search-field {
  margin-right: 0 !important;
}
.search-row {
  height: 36px;
}
.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.pointer {
	cursor: pointer
}
</style>
