var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$hasRight('campaigns.manageAll'))?_c('v-card',{staticClass:"TableCard",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-1 pr-1"},[_c('h3',[_vm._v("Projets")])]),_c('v-card-text',{staticClass:"pl-1 pr-2"},[_c('div',{staticClass:"search-row"},[_c('div',{staticClass:"search-field"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search_project'),"single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.projectSearch),callback:function ($$v) {_vm.projectSearch=$$v},expression:"projectSearch"}})],1)]),_c('v-data-table',{staticClass:"bordered",attrs:{"loading":_vm.projects === [],"headers":_vm.projectsHeaders,"items":_vm.projects,"items-per-page":5,"hide-default-footer":_vm.projects.length < 6,"search":_vm.projectSearch},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"pointer",on:{"click":function($event){return _vm.handleClick(item)}}},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.site.name))]),_c('td',[_vm._v(_vm._s(item.customerSite.name))])])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions"},[(_vm.$hasRight('campaigns.accessAdminEdit'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"table-action",attrs:{"color":"#e89005"},on:{"click":function($event){return _vm.openProjectEditionView(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pen ")])]}}],null,true)},[_c('span',[_vm._v("Modifier les droits des utilisateurs")])]):_vm._e()],1)]}}],null,false,1403508596)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }