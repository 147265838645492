<template>
	<div class="DeletePopin">
		<v-card
		flat
		outlined
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>Confirmer la suppression du site {{ site.name }} ?</h3>
			</v-card-title>

			<v-autocomplete

			v-if="site.workspacesInside.length !== 0"
			label="Site de repli"
			:items="selectableSites"
			item-text="name"
			item-value="id"
			v-model="fallbackSiteId"
			outlined
			/>

			<div v-if="site.workspacesInside.length !== 0">
				<div>
					Des campagnes sont attribuées à ce site, veuillez choisir un site de repli auquel elles seront transférées
				</div>

				<div 
				class="tw-max-h-[300px] tw-overflow-y-auto tw-border-solid tw-border-[1px] tw-rounded-[4px]"
				>
					<div
					v-for="campaign in getCampaignsFromWorkspaces"
					class="tw-ml-[10px] "
					>
						{{ " • " + campaign.name }}
					</div>
				</div>
			</div>

			<v-card-text class="card_text">
				<p>Êtes-vous sûr de vouloir supprimer le site {{ site.name }} ?</p>

				<p>Cette action est irréversible</p>
			</v-card-text>

			<v-card-actions>
				<LowButtonSlot @click="closePopin">
					Annuler
				</LowButtonSlot>

				<v-spacer/>

				<HighButtonSlot @click="confirmSiteDeletion">
					Valider
				</HighButtonSlot>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
export default {
	props: [
		"site", "sites", "campaigns"
	],
	data(){
		return {
			fallbackSiteId: null,
		};
	},
	computed: {
		selectableSites(){
			return this.sites.filter((site) => site.id !== this.site.id);
		},

		getCampaignsFromWorkspaces(){
			return this.campaigns.filter(campaign => 
				this.site.workspacesInside.includes(campaign.workspace.id)
			);
		}
	},
	methods: {
		closePopin(){
			this.$emit("closePopin");
		},
		async confirmSiteDeletion(){
			await this.$api.sites.delete(this.site.id, this.fallbackSiteId);
			this.$emit("siteDeleted");
			this.closePopin();
		}
	},
	mounted(){
		this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.DeletePopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
